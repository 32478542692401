// extracted by mini-css-extract-plugin
export var cell = "privacy-template-module--cell--3f213";
export var container = "privacy-template-module--container--f6847";
export var dashList = "privacy-template-module--dash-list--0cd3f";
export var flex = "privacy-template-module--flex--8ec16";
export var intro = "privacy-template-module--intro--38a4b";
export var list = "privacy-template-module--list--1ea93";
export var listItem = "privacy-template-module--list-item--7af22";
export var overflow = "privacy-template-module--overflow--b1f62";
export var section = "privacy-template-module--section--e8bb0";
export var small = "privacy-template-module--small--c034b";
export var table = "privacy-template-module--table--1bc9b";
export var tableEven = "privacy-template-module--table-even--d0813";
export var tableFour = "privacy-template-module--table-four--0fa02";
export var tableRow = "privacy-template-module--table-row--5a830";
export var tableWrapper = "privacy-template-module--table-wrapper--6b542";
export var underline = "privacy-template-module--underline--47eaf";
export var wrapper = "privacy-template-module--wrapper--29429";