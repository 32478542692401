import React from 'react';

import {
    container,
    wrapper,
    intro,
    section,
    list,
    listItem,
    dashList,
    table,
    tableEven,
    tableFour,
    tableRow,
    tableWrapper,
    overflow,
    underline,
    cell,
    flex,
    small,
} from './privacy-template.module.scss';

const PrivacyTemplate = () => {
    return (
        <div className={container}>
            <div className={wrapper}>
                <section className={`${intro} ${section}`}>
                    <h2>L’Oréal Polska Sp. z o.o.</h2>
                    <h1>POLITYKA PRYWATNOŚCI</h1>
                    <p>
                        Ambicją L’Oréal jest stanie się wzorcowym przykładem społecznie
                        odpowiedzialnego biznesu, tak aby uczynić świat piękniejszym miejscem.
                        Przywiązujemy wielką wagę do uczciwości oraz przejrzystości oraz jesteśmy
                        zaangażowani w budowanie silnej oraz trwałej relacji z naszymi konsumentami,
                        opartej o wzajemne zaufanie oraz obustronną korzyść. Częścią tego
                        zaangażowania jest zabezpieczenie oraz poszanowanie Twojej prywatności oraz
                        Twoich wyborów. Poszanowanie prywatności jest dla nas kluczowe. Z tego
                        powodu przyjęliśmy „Nasze Zobowiązanie w sprawie Prywatności” oraz pełną
                        Politykę Prywatności, zaprezentowaną poniżej.{' '}
                    </p>
                    <p>NASZE ZOBOWIĄZANIE W SPRAWIE PRYWATNOŚCI</p>
                    <ol>
                        <li>Szanujemy Twoją prywatność oraz wybory</li>
                        <li>
                            Zapewniamy, że prywatność oraz bezpieczeństwo są wbudowane we wszystkie
                            nasze działania.
                        </li>
                        <li>
                            Nie przesyłamy Ci informacji marketingowych, chyba że nas o to
                            poprosiłeś. Możesz zmienić zdanie w każdej chwili.
                        </li>
                        <li>Nigdy nie oferujemy Twoich danych, ani ich nie sprzedajemy. </li>
                        <li>
                            Zobowiązujemy się przechowywać Twoje dane w bezpieczny sposób. Obejmuje
                            to współpracę jedynie z zaufanymi partnerami
                        </li>
                        <li>
                            Zobowiązujemy się do otwartości oraz przejrzystości odnośnie tego w jaki
                            sposób wykorzystujemy Twoje dane.
                        </li>
                        <li>
                            Nie wykorzystujemy Twoich danych w sposób, o którym Cię nie
                            poinformowaliśmy
                        </li>
                        <li>
                            Szanujemy Twoje prawa. Zawsze staramy się spełnić Twoje wnioski w takim
                            stopniu, w jakim to możliwe, zgodnie z naszymi prawnymi oraz
                            operacyjnymi obowiązkami.
                        </li>
                    </ol>
                    <p>
                        Aby przekazać więcej informacji odnośnie naszych działań w obszarze
                        prywatności, poniżej wskazujemy jakie rodzaje danych osobowych możemy
                        pozyskać bezpośrednio od Ciebie lub na skutek Twojego korzystania z naszych
                        produktów, w jaki sposób je wykorzystujemy, z kim się nimi dzielimy oraz w
                        jaki sposób je chronimy. Przedstawiamy również Twoje prawa dotyczące Twoich
                        danych osobowych. Naturalnie, nie wszystkie opisane sytuacje mogą mieć
                        zastosowanie do Ciebie. Polityka Prywatności daje Ci ogląd wszystkich
                        możliwych sytuacji w jakich może dojść do interakcji pomiędzy Tobą a naszą
                        firmą.
                    </p>
                    <p>
                        Im więcej się z nami komunikujesz, tym więcej przekazujesz nam informacji o
                        sobie. Pozwala nam to na zaoferowanie usług najlepiej dopasowanych do
                        Ciebie.
                    </p>
                    <p>
                        Kiedy dzielisz się z nami swoimi danymi osobowymi lub kiedy zbieramy dane
                        osobowe na Twój temat, wykorzystujemy je zgodnie z niniejszą Polityką.
                        Prosimy abyś uważnie zapoznał się z tą informacją oraz naszą stroną
                        zawierającą pytania i odpowiedzi (jeśli jest dostępna). Jeżeli masz
                        jakiekolwiek pytania lub wątpliwości odnośnie swoich danych osobowych,
                        prosimy o kontakt na adres email:{' '}
                        <a href={'mailto:personal-da@loreal.com'}>personal-da@loreal.com</a>
                    </p>
                    <p>JAKIE ELEMENTY ZNAJDUJĄ SIĘ W POLITYCE PRYWATNOŚCI?</p>
                    <ul>
                        <li>
                            <a href="#policy-1">Kim jesteśmy?</a>
                        </li>
                        <li>
                            <a href="#policy-2">Czym są dane osobowe?</a>
                        </li>
                        <li>
                            <a href="#policy-3">
                                Na jakich zasadach i na jakiej podstawie przetwarzamy Twoje dane?
                            </a>
                        </li>
                        <li>
                            <a href="#policy-4">
                                Jakie dane od Ciebie zbieramy oraz jak z nich korzystamy?
                            </a>
                        </li>
                        <li>
                            <a href="#policy-5">
                                W jaki sposób zbieramy lub otrzymujemy Twoje dane?
                            </a>
                        </li>
                        <li>
                            <a href="#policy-6">Zautomatyzowane podejmowanie decyzji</a>
                        </li>
                        <li>
                            <a href="#policy-7">Profilowanie</a>
                        </li>
                        <li>
                            <a href="#policy-8">Kto może mieć dostęp do Twoich danych osobowych?</a>
                        </li>
                        <li>
                            <a href="#policy-9">Gdzie przechowujemy Twoje dane osobowe?</a>
                        </li>
                        <li>
                            <a href="#policy-10">Jak długo przechowujemy Twoje dane osobowe?</a>
                        </li>
                        <li>
                            <a href="#policy-11">Czy moje dane osobowe są bezpieczne?</a>
                        </li>
                        <li>
                            <a href="#policy-12">
                                Linki do stron internetowych należących do stron trzecich oraz login
                                społecznościowy
                            </a>
                        </li>
                        <li>
                            <a href="#policy-13">
                                Media społecznościowe oraz treść tworzona przez użytkownika
                            </a>
                        </li>
                        <li>
                            <a href="#policy-14">Twoje prawa oraz wybory</a>
                        </li>
                        <li>
                            <a href="#policy-15">Zmiany w naszej Polityce Prywatności</a>
                        </li>
                        <li>
                            <a href="#policy-16">Kontakt</a>
                        </li>
                    </ul>
                </section>
                <section id="policy-1" className={section}>
                    <h3>KIM JESTEŚMY</h3>
                    <p>
                        Firma L’Oréal Polska Sp. Z o.o. jest odpowiedzialna za dane osobowe, którymi
                        się z nami dzielisz. Kiedy mówimy „L’Oréal”, „nam”, „nasze”, „my”, właśnie
                        do tego podmiotu się odnosimy. L’Oréal jest „administratorem” w rozumieniu
                        właściwego prawa ochrony danych.
                    </p>
                    <p>
                        L'Oréal Polska Sp. z o.o. <br />
                        Ul. Grzybowska 62 <br />
                        00-844 Warszawa <br />
                        Polska
                    </p>{' '}
                    <br />
                    <p>
                        Spółka powołała Inspektora Ochrony Danych dostępnego pod adresem{' '}
                        <a href="personal-da@loreal.com">personal-da@loreal.com</a>.
                    </p>
                </section>
                <section id="policy-2" className={section}>
                    <h3>CZYM SĄ DANE OSOBOWE?</h3>
                    <p>
                        “Dane osobowe” oznaczają każdą informację lub część informacji dotyczącą
                        zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej (“osoba,
                        której dane dotyczą”), która może być zidentyfikowana bezpośrednio (np.
                        Twoje imię) lub pośrednio (np. poprzez dane spseudonimizowane, takie jak
                        unikalny numer identyfikujący). Oznacza to, że dane osobowe obejmują takie
                        dane jak adres email, adres domowy, numer telefonu komórkowego, nazwę
                        użytkownika, zdjęcia profilowe, preferencje oraz nawyki zakupowe, treść
                        generowaną przez użytkownika, informacje finansowe oraz na temat statusu
                        finansowego. Mogą również obejmować unikalne identyfikatory numeryczne,
                        takie jak adres IP Twojego komputera lub adres MAC Twojego urządzenia
                        przenośnego, jak również pliki Cookies.
                    </p>
                </section>
                <section id="policy-3" className={section}>
                    <h3>NA JAKICH ZASADACH I NA JAKIEJ PODSTAWIE PRZETWARZAMY TWOJE DANE</h3>
                    <p>
                        Dokładamy staranności w celu ochrony interesów osób, których dane dotyczą, a
                        w szczególności zapewniamy, aby dane te były:
                    </p>{' '}
                    <br />
                    <ul className={list}>
                        <li className={listItem}>
                            przetwarzane zgodnie z prawem, rzetelnie i w sposób przejrzysty dla
                            osoby, której dane dotyczą;
                        </li>
                        <li className={listItem}>
                            zbierane w konkretnych, wyraźnych i prawnie uzasadnionych celach i
                            nieprzetwarzane dalej w sposób niezgodny z tymi celami;
                        </li>
                        <li className={listItem}>
                            adekwatne, stosowne oraz ograniczone do tego, co niezbędne do celów, w
                            których są przetwarzane;
                        </li>
                        <li className={listItem}>
                            prawidłowe i w razie potrzeby uaktualniane; podejmujemy działania, aby
                            dane osobowe, które są nieprawidłowe w świetle celów ich przetwarzania,
                            zostały niezwłocznie usunięte lub sprostowane;{' '}
                        </li>
                        <li className={listItem}>
                            przechowywane w formie umożliwiającej identyfikację osoby, której dane
                            dotyczą, przez okres nie dłuższy, niż jest to niezbędne do celów;
                        </li>
                        <li className={listItem}>
                            przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych
                            osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z prawem
                            przetwarzaniem oraz przypadkową utratą, zniszczeniem.
                        </li>
                    </ul>
                </section>
                <section id="policy-4" className={section}>
                    <h3>JAKIE DANE OD CIEBIE ZBIERAMY ORAZ W JAKI SPOSÓB JE WYKORZYSTUJEMY?</h3>
                    <p>
                        L'Oréal wierzy, że to właśnie Ty, nasz konsument, znajduje się w sercu
                        naszych działań. Bardzo cieszymy się gdy się z nami kontaktujesz, sprawia
                        nam radość uczenie się Ciebie oraz tworzenie oraz dostarczanie produktów,
                        którymi będziesz się cieszył. Wiemy również, ze wiele z Was uwielbia
                        komunikować się z nami. Z tego powodu istnieje wiele sposobów, na które
                        możesz dzielić się z nami swoimi danymi osobowymi, a my je zbierać
                    </p>
                </section>
                <section id="policy-5" className={`${section} ${overflow}`}>
                    <h3>W jaki sposób zbieramy lub otrzymujemy Twoje dane?</h3>
                    <p>
                        Możemy zbierać lub otrzymywać dane od Ciebie poprzez naszą stronę,
                        formularze, aplikacje, urządzenia, strony produktów L’Oréal oraz strony
                        marek na mediach społecznościowych lub w inny sposób. Czasami podajesz je
                        nam bezpośrednio (np. gdy tworzysz konto, gdy się z nami kontaktujesz, kiedy
                        dokonujesz zakupów na naszej stronie/ aplikacji lub w sklepie/ salonie
                        piękności), czasami to my je zbieramy (np. poprzez pliki Cookies, aby
                        zrozumieć w jaki sposób korzystasz z naszej strony/ aplikacji) a czasami
                        otrzymujemy dane od stron trzecich, co obejmuje inne podmioty z Grupy
                        L’Oréal.
                    </p>
                    <p>
                        Gdy zbieramy dane, oznaczamy pola obowiązkowe kropką, gdy takie dane są nam
                        niezbędne do:
                    </p>
                    <ul className={dashList}>
                        <li>
                            - wykonania umowy z Tobą (np. do dostarczenia produktów, które kupiłeś
                            na naszych stronach/ poprzez aplikacje);
                        </li>
                        <li>
                            - dostarczenia usługi, o którą się zwróciłeś (np. przesyłania Ci
                            newsletter’a); lub
                        </li>
                        <li>
                            - zapewnienia zgodności z wymogami prawnymi (np. do wystawiania faktur).
                        </li>
                    </ul>
                    <p>
                        Jeżeli nie przekażesz danych oznaczonych kropką, może to wpłynąć na naszą
                        zdolność do dostarczenia Ci produktów lub usług.{' '}
                    </p>
                    <p>W poniższej tabeli przedstawiamy dalsze szczegóły wyjaśniające:</p>
                    <ol>
                        <li>
                            <b>
                                W ramach jakiej interakcji Twoje dane mogą być przekazane lub
                                zebrane?
                            </b>{' '}
                            Niniejsza kolumna wyjaśnia przy podejmowaniu jakich czynności oraz w
                            jakich sytuacjach wykorzystujemy oraz zbieramy Twoje dane. Na przykład
                            gdy dokonujesz zakupu, zapisujesz się na newsletter lub przeszukujesz
                            stronę internetową/ aplikację.
                        </li>
                        <li>
                            <b>
                                Jakie dane osobowe możemy zbierać bezpośrednio od Ciebie, a jakie w
                                związku z Twoją z nami interakcją?
                            </b>{' '}
                            Niniejsza kolumna wyjaśnia jakie typy danych możemy zbierać na Twój
                            temat, w zależności od sytuacji.
                        </li>
                        <li>
                            <b>W jaki sposób oraz z jakiego powodu możemy je wykorzystać?</b>{' '}
                            Niniejsza kolumna wyjaśnia w jaki sposób możemy wykorzystywać Twoje dane
                            oraz dla jakich celów.
                        </li>
                        <li>
                            <b>Jaka jest podstawa prawna wykorzystania Twoich danych?</b> Niniejsza
                            kolumna wyjaśnia dlaczego możemy móc wykorzystywać Twoje dane. <br />W
                            zależności od celu dla którego dane są wykorzystywane, podstawą prawną
                            przetwarzania może być:
                            <ul className={list}>
                                <li>
                                    Twoja dobrowolna <b>zgoda;</b>
                                </li>
                                <li>
                                    Nasz <b>uzasadniony interes,</b> którym może być:
                                    <ul className={list}>
                                        <li>
                                            <b>Poprawa naszych produktów i usług:</b> bardziej
                                            szczegółowo, nasz interes biznesowy polegający na
                                            umożliwieniu nam lepszego zrozumienia Twoich potrzeb i
                                            oczekiwań i w ten sposób polepszenia usług, stron
                                            internetowych/ aplikacji/ urządzeń, produktów oraz marek
                                            na korzyść naszych konsumentów
                                        </li>
                                        <li>
                                            <b>Zapobieganie oszustwom:</b> w celu zapewnienia, że
                                            płatność została dokonana oraz, aby uwolnić się od
                                            oszustw oraz sprzeniewierzeń
                                        </li>
                                        <li>
                                            <b>Zabezpieczenie naszych narzędzi:</b> aby zapewnić
                                            bezpieczeństwo naszym narzędziom (stronom internetowym/
                                            aplikacjom/ urządzeniom) oraz zapewnić, że działają
                                            prawidłowo oraz podlegają stałemu ulepszaniu.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Wykonanie umowy:</b> bardziej szczegółowo w celu dostarczenia
                                    usług, o które się zwróciłeś
                                </li>
                                <li>
                                    <b>Zobowiązanie prawne: </b> gdy przetwarzanie jest wymagane
                                    przez prawo.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>
                                Czy dziecko może wyrazić samodzielnie zgodę na przetwarzanie danych?
                            </b>{' '}
                            L’Oréal zbiera i przetwarza dane osobowe jedynie osób, które według daty
                            urodzenia ukończyły 16 roku życia.
                        </li>
                    </ol>
                    <br />
                    <p className={underline}>
                        Przegląd informacji na temat Twoich z nami interakcji oraz ich konsekwencji
                        dla Twoich danych
                    </p>
                    <div className={`${table} ${tableFour}`}>
                        <div className={tableRow}>
                            <p>
                                <b>
                                    W ramach jakiej interakcji Twoje dane mogą być przekazane lub
                                    zebrane
                                </b>
                            </p>
                            <p>
                                <b>
                                    Jakie dane osobowe możemy zbierać od Ciebie bezpośrednio a jakie
                                    na skutek Twojej z nami interakcji?
                                </b>
                            </p>
                            <div className={tableWrapper}>
                                <p>
                                    <b>
                                        W jaki sposób oraz z jakiego powodu możemy wykorzystywać
                                        Twoje dane?
                                    </b>
                                </p>
                                <p>
                                    <b>
                                        Jaka jest podstawa prawna wykorzystania Twoich danych
                                        osobowych?
                                    </b>
                                </p>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Utworzenie oraz zarządzanie kontem</b> <br /> <br />
                                Informacje zebrane w trakcie tworzenia konta na stronie/ aplikacji
                                L’Oréal, poprzez login mediów społecznościowych lub w sklepie.
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować:{' '}
                                <ul className={list}>
                                    <li>imię i nazwisko;</li>
                                    <li>płeć;</li>
                                    <li>adres email;</li>
                                    <li>adres;</li>
                                    <li>numer telefonu;</li>
                                    <li>zdjęcie;</li>
                                    <li>data urodzenia oraz przedział wiekowy;</li>
                                    <li>numer ID, nazwa użytkownika oraz hasło;</li>
                                    <li>opis osobisty lub preferencje;</li>
                                    <li>szczegóły zamówienia;</li>
                                    <li>
                                        profil mediów społecznościowych (kiedy wykorzystujesz login
                                        społecznościowy lub dzielisz się z nami tymi danymi
                                        osobowymi).
                                    </li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        W celu:
                                        <ul className={list}>
                                            <li>zarządzania Twoimi zamówieniami;</li>
                                            <li>
                                                udzielania odpowiedzi na Twoje pytania oraz w inny
                                                sposób komunikowania się z Tobą;
                                            </li>
                                            <li>
                                                umożliwienia Ci zarządzania swoimi preferencjami;
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Wykonanie umowy</b> <br />W celu dostarczenia Ci
                                                usług, o które się zwróciłeś (np. utworzenie konta,
                                                wypełnienia ankiety lub zakupu produktu).
                                            </li>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />W celu efektywnego
                                                zarządzania biznesowego, promowania produktów
                                                poprzez organizowanie ankiet, odpowiadanie na Twoje
                                                pytania oraz w inny sposób komunikowania się z Tobą,
                                                umożliwienia CI zarządzania swoimi preferencjami.
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                wysyłania do Ciebie informacji marketingowych
                                                (jeżeli się do nas o to zwróciłeś), które mogą być
                                                dopasowane do Twojego „profilu” (tj. oparte na
                                                Twoich danych osobowych, które znamy oraz Twoich
                                                preferencji).{' '}
                                            </li>
                                            <li>
                                                zarządzania konkursami, promocjami, ankietami lub
                                                zawodami w których zdecydujesz się uczestniczyć;
                                            </li>
                                            <li>zaoferowania CI programu lojalnościowego;</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zgoda</b> <br />W celu wysyłania do Ciebie
                                                bezpośrednich informacji marketingowych,
                                                organizowania konkursów, promocji, ankiet, zawodów,
                                                programu lojalnościowego
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                Wykorzystywania oprogramowania typu analytics w celu
                                                oferowania spersonalizowanych usług opartych o Twoją
                                                charakterystykę urody;
                                            </li>
                                            <li>
                                                Monitorowania oraz poprawiania naszych stron/
                                                aplikacji; zabezpieczania naszych stron/ aplikacji
                                                oraz ochrony Ciebie oraz nas przed oszustwami.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />
                                                Aby zapewnić, że nasze strony/ aplikacje pozostają
                                                bezpieczne, w celu ochrony ich przed oszustwem oraz
                                                w celu umożliwienia nam lepszego zrozumienia Twoich
                                                potrzeb oraz oczekiwań i poprawienia w ten sposób
                                                naszych usług, produktów oraz marek.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Subskrypcja do newsletteru oraz informacji handlowych</b>
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować: <br />
                                <ul className={list}>
                                    <li>adres email;</li>
                                    <li>imię i nazwisko;</li>
                                    <li>opis osobisty lub preferencje; </li>
                                    <li>
                                        profil mediów społecznościowych (kiedy wykorzystujesz login
                                        społecznościowy lub dzielisz się z nami tymi danymi
                                        osobowymi).
                                    </li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        W celu:
                                        <ul className={list}>
                                            <li>
                                                przesyłania Ci informacji marketingowych (jeżeli się
                                                o to zwróciłeś), które mogą być dostosowane do
                                                Twojego „profilu”, opartego na Twoich danych
                                                osobowych, które znamy oraz Twoich preferencjach (co
                                                obejmuje lokalizację Twojego ulubionego sklepu);
                                            </li>
                                            <li>
                                                Korzystania z oprogramowania typu analytics lub
                                                zbierania statystyk w celu
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zgoda</b> <br />W celu wysyłania do Ciebie
                                                bezpośrednich informacji marketingowych.
                                            </li>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />W celu dostosowania
                                                naszych komunikatów marketingowych, zrozumienia ich
                                                skuteczności oraz zapewnienia, że otrzymasz
                                                najbardziej odpowiednie komunikaty; w celu lepszego
                                                zrozumienia przez nas Twoich potrzeb oraz oczekiwań
                                                i poprawienia w ten sposób naszych usług, produktów
                                                oraz marek.
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                Uaktualniania listy osób, które nie życzą sobie
                                                kontaktu, jeżeli zwróciłeś się o niekontaktowanie
                                                się
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zobowiązanie prawne oraz uzasadniony interes</b>{' '}
                                                <br />
                                                Umieszczenie Twoich danych na liście osób, które nie
                                                życzą sobie kontaktu, jeżeli zwróciłeś się o
                                                nieprzysłanie Ci więcej informacji marketingowych.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Zakupy oraz zarządzanie zamówieniem; </b> <br /> <br />
                                Informacje zebrane w trakcie procesu zakupu na stronie/ aplikacji/
                                stronie w mediach społecznościowych lub w sklepie L’Oréal
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować: <br />
                                <ul className={list}>
                                    <li>imię i nazwisko; </li>
                                    <li>adres email;</li>
                                    <li>adres (przesyłka oraz fakturowanie);</li>
                                    <li>numer telefonu;</li>
                                    <li>osobisty opis lub preferencje;</li>
                                    <li>
                                        profil mediów społecznościowych (kiedy wykorzystujesz login
                                        społecznościowy lub dzielisz się z nami tymi danymi
                                        osobowymi);
                                    </li>
                                    <li>
                                        informacje na temat transakcji, co obejmuje zakup produktów
                                        oraz lokalizację sklepu;
                                    </li>
                                    <li>płatność oraz informacje; lub</li>
                                    <li>historię zakupów</li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        W celu:
                                        <ul className={list}>
                                            <li>
                                                skontaktowania się z Tobą w celu finalizacji Twojego
                                                zamówienia, jeżeli zachowałeś swój koszyk zakupowy
                                                lub umieściłeś produkt w swoim koszyku, bez
                                                ukończenia procesu zakupu;
                                            </li>
                                            <li>
                                                poinformowania Cię, kiedy produkt, który chciałeś
                                                kupić będzie dostępny;
                                            </li>
                                            <li>
                                                przetwarzania oraz śledzenia Twojego zamówienia, co
                                                obejmuje dostarczenie produktu na wskazany adres;
                                            </li>
                                            <li>
                                                zarządzanie płatnością dotyczącą Twojego zamówienia.
                                                Należy zauważyć, że informacje dotyczące płatności
                                                (numer karty kredytowej/ informacje Paypal/
                                                szczegóły dotyczące konta bankowego) nie są zbierane
                                                przez nas, ale bezpośrednio przez dostawców usług
                                                obsługi płatności;
                                            </li>
                                            <li>
                                                zarządzania Twoimi kontaktami z nami dotyczącymi
                                                Twojego zamówienia.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Wykonanie umowy</b> <br />
                                                Aby dostarczyć Ci usługę, o którą się zwróciłeś
                                                (zakup)
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                Zabezpieczenia transakcji przed oszustwami. Należy
                                                zauważyć, że korzystamy z rozwiązań stron trzecich w
                                                celu wykrywania oszustw oraz zapewnienia, że
                                                płatność jest wykonywana przez Ciebie lub osobę
                                                przez Ciebie upoważnioną;
                                            </li>
                                            <li>
                                                Wzbogacenia Twojego profilu, jeżeli dokonasz zakupu
                                                wykorzystując informacje ze swojego konta;
                                            </li>
                                            <li>Mierzenia satysfakcji;</li>
                                            <li>
                                                Zarządzania wszelkimi sporami dotyczącymi zakupów;
                                            </li>
                                            <li>w celach statystycznych</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />
                                                Aby chronić Ciebie oraz nas przed nieuczciwymi
                                                transakcjami oraz w celu zapewnienia, że płatność
                                                jest wolna od oszustwa oraz sprzeniewierzeń.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p className={flex}>
                                <b>Wyszukiwanie online</b> <br /> <br />
                                Informacje zbierane za pomocą plików Cookies lub podobnych
                                technologii <b>("Cookies"*)</b> w związku z wyszukiwaniem
                                dokonywanym przez Ciebie na stronach/ aplikacjach L’Oréal lub stron
                                trzecich. <br /> <br /> <br />
                                Aby uzyskać szczegółową informację na temat Cookies umieszczonych na
                                poszczególnych stronach/ aplikacjach, prosimy o zapoznanie się z
                                odpowiednią tabelką dotyczącą plików Cookies.
                                <span className={small}>
                                    * Pliki Cookies to małe pliki tekstowe przechowywane na Twoimi
                                    urządzeniu (komputer, tablet lub telefon przenośny), kiedy
                                    korzystasz z Internetu, co obejmuje strony internetowe Grupy
                                    L’Oréal.{' '}
                                </span>
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować: <br />
                                <ul className={list}>
                                    <li>
                                        Dane dotyczące Twojego korzystania z naszych stron/
                                        aplikacji.
                                    </li>
                                    <li>Informacje skąd pochodzisz;</li>
                                    <li>Szczegóły dotyczące loginu;</li>
                                    <li>Strony na które wszedłeś;</li>
                                    <li>Nagrania wideo, które oglądałeś;</li>
                                    <li>Reklamy, które kliknąłeś lub zaznaczyłeś;</li>
                                    <li>Produkty, których szukałeś;</li>
                                    <li>Twoją lokalizację;</li>
                                    <li>Czas trwania Twojej wizyty;</li>
                                    <li>Produkty, które wybrałeś, aby utworzyć koszyk</li>
                                </ul>{' '}
                                <br />
                                Informacje techniczne:
                                <ul className={list}>
                                    <li>adres IP;</li>
                                    <li>informacje o przeglądarce; </li>
                                    <li>informacje o urządzeniu.</li>
                                </ul>{' '}
                                <br />
                                Unikalny identyfikator przyznawany każdemu odwiedzającemu oraz datę
                                wygaśnięcia takiego identyfikatora.
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        Korzystamy z plików Cookies, gdzie to odpowiednie, wraz z
                                        innymi danymi osobowymi, którymi się z nami podzieliłeś
                                        (takimi jak poprzednie zakupy czy informacja o wpisaniu się
                                        do newsletter’a), lub w następujących celach:
                                        <ul className={list}>
                                            <li>
                                                umożliwienia należytego funkcjonowania naszej
                                                strony/ aplikacji:
                                                <ul className={list}>
                                                    <li>należytego wyświetlania zawartości;</li>
                                                    <li>utworzenia oraz zapamiętania karty;</li>
                                                    <li>utworzenia oraz zapamiętania karty;</li>
                                                    <li>
                                                        utworzenia oraz zapamiętania Twojego loginu;
                                                    </li>
                                                    <li>
                                                        personalizacji interfejsu, takiej jak wybór
                                                        języka;
                                                    </li>
                                                    <li>
                                                        parametrów Twojego urządzania, co obejmuje
                                                        rozdzielność wyświetlacza, itd.;
                                                    </li>
                                                    <li>
                                                        poprawiania naszych stron/ aplikacji, na
                                                        przykład poprzez testowanie nowych pomysłów.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                W celu zapewnienia bezpieczeństwa strony/ aplikacji
                                                oraz chronienia Cię przed oszustwem lub nienależytym
                                                korzystaniem z naszych stron lub usług, np. poprzez
                                                rozwiązywanie problemów.
                                            </li>{' '}
                                            <br />
                                            <li>
                                                W celu prowadzenia statystyk:{' '}
                                                <ul className={list}>
                                                    <li>
                                                        Aby uniknąć dwukrotnego odnotowywania
                                                        użytkowników;
                                                    </li>
                                                    <li>
                                                        Aby poznać reakcje użytkowników na nasze
                                                        kampanie reklamowe, jeżeli wyraziłeś na to
                                                        zgodę
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes:</b> <br />
                                                Aby zapewnić, że pliki Cookies: (i) są kluczowe dla
                                                funkcjonowania naszych stron/ aplikacji, (ii)
                                                wykorzystywane w celu utrzymania bezpieczeństwa
                                                naszych stron/ aplikacji.
                                            </li>{' '}
                                            <br />
                                            <li>
                                                <b>Zgoda</b> <br />W celu poprawienia naszych ofert,
                                                usług; Aby dowiedzieć się w jaki sposób odkryłeś
                                                naszą stronę/ aplikację; W celu przesyłania reklam,
                                                dostarczania dopasowanej treści.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                W celu dostarczenia internetowej reklamy
                                                behawioralnej:
                                                <ul className={list}>
                                                    <li>
                                                        Aby pokazać Ci reklamy internetowe
                                                        produktów, które mogą Cię zainteresować,
                                                        opierając się na Twoim wcześniejszym
                                                        zachowaniu;
                                                    </li>
                                                    <li>
                                                        Aby pokazać Ci reklamy oraz zawartość na
                                                        platformach mediów społecznościowych;
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                W celu dostosowania naszych usług do Ciebie:
                                                <ul className={list}>
                                                    <li>
                                                        Aby wysyłać Ci rekomendacje, informacje
                                                        marketingowe lub treść w oparciu o Twój
                                                        profil oraz zainteresowania;
                                                    </li>
                                                    <li>
                                                        Aby wyświetlać nasze strony/ aplikacje w
                                                        dostosowany sposób, taki jak np.
                                                        zapamiętanie Twojej strony lub loginu,
                                                        ustawień językowych, plików Cookies
                                                        określających ustawienia Twojego interfejsu
                                                        (tj. parametry Twojego urządzenia, co
                                                        obejmuje rozdzielczość ekranu, preferencje
                                                        dotyczące czcionki, itd.){' '}
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                W celu umożliwienia dzielenia się naszą treścią na
                                                portalach społecznościowych (odnośniki służące do
                                                wyświetlania innych stron).{' '}
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zgoda</b> <br />
                                                Na wszystkie inne pliki Cookies.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Działalność promocyjna </b> <br /> <br />
                                Informacje zebrane w trakcie gier, zawodów, ofert promocyjnych,
                                prośbach o próbkę, ankiet.
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować: <br />
                                <ul className={list}>
                                    <li>imię i nazwisko; </li>
                                    <li>adres email;</li>
                                    <li>numer telefonu;</li>
                                    <li>zdjęcie;</li>
                                    <li>data urodzenia;</li>
                                    <li>płeć;</li>
                                    <li>adres;</li>
                                    <li>opis osobisty lub preferencje;</li>
                                    <li>
                                        profil mediów społecznościowych (kiedy wykorzystujesz login
                                        społecznościowy lub dzielisz się z nami tymi danymi
                                        osobowymi);
                                    </li>
                                    <li>
                                        inne informacje dotyczące Ciebie, którymi się z nami
                                        podzieliłeś (np. przez stronę „Moje Konto”, kontaktując się
                                        z nami, lub dodając własną treść, np. zdjęcie, recenzję lub
                                        pytanie zadane przez chat dostępny na niektórych stronach/
                                        aplikacjach, lub przez uczestnictwo w zawodach, grze,
                                        ankiecie.{' '}
                                    </li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                W celu przeprowadzenia działań o które się do nas
                                                zwróciłeś, na przykład zarządzania Twoim
                                                uczestnictwem w konkursach, grach lub ankietach, co
                                                obejmuje rozważenie Twoich wrażeń oraz sugestii;
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Wykonanie umowy</b> <br />
                                                Aby dostarczyć Ci usługę, o którą się zwróciłeś.
                                            </li>{' '}
                                            <br />
                                            <li>
                                                <b>Uzasadniony interes</b> <br />
                                                Skuteczne zarządzanie biznesowe, promowanie
                                                produktów przez zapewnianie Twojego uczestnictwa w
                                                konkursach, grach oraz ankietach, co obejmuje
                                                rozważenie Twoich wrażeń oraz sugestii.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>Aby prowadzić statystyki w celu:</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />w celu lepszego
                                                zrozumienia przez nas Twoich potrzeb oraz oczekiwań
                                                i poprawienia w ten sposób naszych usług, produktów
                                                oraz marek.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                Aby wysyłać Ci informacje marketingowe (jeżeli się
                                                do nas o to zwróciłeś).
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zgoda</b> <br />
                                                Na wysyłanie do Ciebie bezpośrednich informacji
                                                marketingowych.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Treść generowana przez użytkowników</b> <br /> <br />
                                Informacje zebrane, gdy przekazujesz nam pewną treść na jednej z
                                naszych platform społecznościowych lub akceptujesz ponowne
                                wykorzystanie przez nas treści, którą umieściłeś na platformach
                                mediów społecznościowych.
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować:
                                <br />
                                <ul className={list}>
                                    <li>imię i nazwisko lub pseudonim;</li>
                                    <li>adres email;</li>
                                    <li>zdjęcie;</li>
                                    <li>opis osobisty lub preferencje; </li>
                                    <li>
                                        profil mediów społecznościowych (kiedy wykorzystujesz login
                                        społecznościowy lub dzielisz się z nami tymi danymi
                                        osobowymi);
                                    </li>
                                    <li>
                                        inne informacje dotyczące Ciebie, którymi się z nami
                                        podzieliłeś (np. przez stronę „Moje konto”, kontaktując się
                                        z nami, lub dodając własną treść, np. zdjęcie, recenzję lub
                                        pytanie zadane przez chat dostępny na niektórych stronach/
                                        aplikacjach, lub przez uczestnictwo w zawodach, grze,
                                        ankiecie.
                                    </li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                Zgodnie z konkretnymi regulaminami oraz warunkami,
                                                na które wyraziłeś zgodę:
                                                <ul className={list}>
                                                    <li>
                                                        W celu umieszczenia Twojej recenzji lub
                                                        treści w celu promocji naszych produktów;
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zgoda</b> <br />
                                                Na ponowne wykorzystanie treści umieszczonej przez
                                                Ciebie w Internecie.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>Aby prowadzić statystyki w celu:</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />w celu lepszego
                                                zrozumienia przez nas Twoich potrzeb oraz oczekiwań
                                                i poprawienia w ten sposób naszych usług, produktów
                                                oraz marek.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Wykorzystanie aplikacji oraz urządzeń</b> <br /> <br />
                                Informacje zbierane w związku z korzystaniem przez Ciebie z naszych
                                aplikacji/ urządzeń.
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować:
                                <br />
                                <ul className={list}>
                                    <li>imię i nazwisko;</li>
                                    <li>adres email;</li>
                                    <li>lokalizację;</li>
                                    <li>datę urodzenia;</li>
                                    <li>opis osobisty lub preferencje;</li>
                                    <li>zdjęcie;</li>
                                    <li>
                                        dane dotyczące urody obejmujące odcień skóry, rodzaj skóry/
                                        włosów;
                                    </li>
                                    <li>dane geolokalizacyjne.</li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        Aby
                                        <ul className={list}>
                                            <li>
                                                Dostarczyć Ci usługi o którą się zwróciłeś (np.
                                                realnego przetestowania naszego produktu, zakupu
                                                produktu poprzez aplikację lub powiązaną stronę
                                                internetową zajmującą się handlem elektronicznym,
                                                przekazania informacji oraz rad dotyczących Twojej
                                                wrażliwości na słońce, nawyków pielęgnacyjnych
                                                włosów;
                                            </li>
                                            <li>
                                                Przeanalizować charakterystykę Twojej urody oraz
                                                zarekomendować Ci odpowiednie produkty (co obejmuje
                                                zindywidualizowane produkty) oraz sposoby
                                                postępowania;
                                            </li>
                                            <li>
                                                Przedstawić Ci rekomendacje dotyczące produktów oraz
                                                sposobów postępowania;
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Wykonanie umowy oraz uzasadniony interes</b>{' '}
                                                <br />W celu dostarczenia Ci usług, o które się
                                                zwróciłeś (co obejmuje, gdzie to konieczne, analizę
                                                algorytmu koniecznego do dostarczenia usługi, przez
                                                zespół ds. badań oraz innowacji).
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                W celu prowadzenia badań oraz wprowadzania innowacji
                                                przez naukowców z Grupy L’Oréal, w celu zapewnienia
                                                ewolucji naszych produktów;
                                            </li>
                                            <li>
                                                W celu monitorowania oraz poprawiania naszych
                                                aplikacji oraz urządzeń;{' '}
                                            </li>
                                            <li>Aby prowadzić statystyki w celu</li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />
                                                Aby ciągle ulepszać nasze produkty Ii usługi, tak by
                                                odpowiadały Twoim potrzebom oraz oczekiwaniom oraz
                                                dla celów badań oraz wprowadzania innowacji.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Pytania</b> <br /> <br />
                                Informacje zebrane gdy zwracasz się do nas z pytaniami (np. przez
                                nasz dział opieki klienta) dotyczącymi naszych marek, produktów oraz
                                ich wykorzystania.
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować:
                                <br />
                                <ul className={list}>
                                    <li>imię i nazwisko; </li>
                                    <li>numer telefonu;</li>
                                    <li>adres email;</li>
                                    <li>
                                        inne informacje na swój temat, którymi się z nami
                                        podzieliłeś w odniesieniu do Twoich pytań (które mogą
                                        dotyczyć urody oraz zdrowia, jeżeli wyraziłeś na to zgodę).{' '}
                                    </li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>Aby odpowiedzieć na Twoje pytania;</li>
                                        </ul>
                                        Jeżeli istnieje potrzeba przyporządkowania do odpowiednich
                                        usług
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zgoda</b> <br />W celu przetwarzania Twoich
                                                pytań.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                W celach statystycznych aby poprawić wydajność oraz
                                                bezpieczeństwo naszych produktów
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes oraz zobowiązanie prawne</b>{' '}
                                                <br />W celu lepszego zrozumienia przez nas potrzeb
                                                oraz oczekiwań konsumentów i poprawienia w ten
                                                sposób naszych usług, produktów oraz marek oraz w
                                                celu udzielenia odpowiedzi na Twoje pytanie.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>Dla celów Cosmétovigilance, aby:</li>
                                            <ul className={list}>
                                                <li>
                                                    Monitorować oraz zapobiegać wszelkim efektom
                                                    niepożądanym, związanym z naszymi produktami;
                                                </li>
                                                <li>
                                                    Prowadzić badania dotyczące bezpiecznego
                                                    wykorzystania naszych produktów;
                                                </li>
                                                <li>
                                                    Wprowadzać oraz nadzorować środki naprawcze,
                                                    podejmowane gdzie to konieczne.{' '}
                                                </li>
                                            </ul>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Zobowiązanie prawne </b> <br />W celu zapewnienia
                                                zgodności z obowiązkiem prawnym monitorowania
                                                niepożądanych skutków swoich produktów. W celu
                                                zapewnienia zgodności z obowiązkiem prawnym oceny
                                                oraz zapewnienia bezpieczeństwa produktów oraz
                                                przekazywania informacji odpowiedniej instytucji w
                                                sytuacji pojawienia się znaczącego efektu ubocznego
                                                produktów.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={tableRow}>
                            <p>
                                <b>Sponsoring</b>
                            </p>
                            <p>
                                W zależności od tego jak często wchodzisz z nami w interakcję, dane
                                te mogą obejmować:
                                <br />
                                <ul className={list}>
                                    <li>imię i nazwisko; </li>
                                    <li>numer telefonu;</li>
                                    <li>adres email;</li>
                                </ul>
                            </p>
                            <div className={tableWrapper}>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                Aby wysyłać informacje dotyczące naszych produktów
                                                lub informacje oznaczone na liście życzeń osoby na
                                                prośbę innej osoby.{' '}
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                <div className={cell}>
                                    <p>
                                        <ul className={list}>
                                            <li>
                                                <b>Wykonanie umowy z osobą, której dane dotyczą.</b>{' '}
                                                <br />
                                                Rozpatrzenie wniosku.
                                            </li>
                                        </ul>
                                        <b>Oraz</b> <br /> <br />
                                        <ul className={list}>
                                            <li>
                                                <b>Uzasadniony interes</b> <br />W celu
                                                skontaktowania się z osobą na wniosek innej osoby.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="policy-6" className={section}>
                    <h3>Automatyczne podejmowanie decyzji</h3>
                    <p>
                        W celach zabezpieczenia transakcji dostępnych na naszych stronach/
                        aplikacjach/ urządzeniach przed oszustwem oraz sprzeniewierzeniem,
                        wykorzystujemy rozwiązania dostarczane przez dostawców, będących stronami
                        trzecimi. Metoda wykrywania oszustw jest na przykład oparta na prostych
                        porównaniach, zestawieniach, klastrach, przewidywaniu oraz obserwacji
                        odstającej, przy wykorzystaniu inteligentnych agentów (typ oprogramowania),
                        technik fuzji danych, oraz różnych technik eksploracji danych.
                    </p>
                    <p>
                        Proces wykrywania oszustw może być całkowicie zautomatyzowany lub może
                        obejmować ludzką interwencję, gdzie to osoba podejmuje ostateczną decyzję. W
                        każdym przypadku, podejmujemy wszelkie rozsądne środki zapobiegawcze oraz
                        zabezpieczenia ograniczające dostęp do Twoich danych.{' '}
                    </p>
                    <p>
                        Na skutek automatycznego wykrywania oszustw: (i) możesz doświadczyć
                        opóźnienia w przetwarzaniu Twojego zamówienia/ wniosku, jeżeli Twoja
                        transakcja jest przez nas sprawdzana; oraz (ii) Twoje korzystanie z usług
                        może być ograniczone lub możesz zostać wykluczony z korzyści oferowanych
                        przez usługę, jeżeli zidentyfikowane zostanie ryzyko oszustwa. Masz prawo
                        dostępu do danych, na których opieramy swoją decyzję. Proszę zapoznać się z
                        poniższym działem „Twoje prawa oraz wybory”.
                    </p>
                    <p>
                        Przetwarzanie to jest konieczne do zawarcia lub wykonania umowy pomiędzy
                        osobą, której dane dotyczą lub administratorem lub jest dopuszczone prawem
                        Unii lub państwa członkowskiego, któremu administrator podlega. Jesteś
                        uprawniony do uzyskania ludzkiej interwencji ze strony administratora,
                        wyrażenia swojej opinii oraz zakwestionowania tej decyzji.{' '}
                    </p>
                </section>
                <section id="policy-7" className={section}>
                    <h3>Profilowanie</h3>
                    <p>
                        Kiedy wysyłamy lub wyświetlamy spersonalizowane informacje lub treść, możemy
                        korzystać z technik określanych jako “profilowanie” (tj. dowolną formę
                        zautomatyzowanego przetwarzania danych osobowych, które polega na
                        wykorzystaniu danych osobowych do oceny niektórych czynników osobowych osoby
                        fizycznej, w szczególności do analizy lub prognozy aspektów dotyczących
                        preferencji osobistych, zainteresowań, sytuacji ekonomicznej, zachowania,
                        lokalizacji, zdrowia, wiarygodności lub przemieszczania się). Oznacza to, że
                        możemy zbierać Twoje dane osobowe w ramach różnych scenariuszy wskazanych w
                        powyższej tabeli. Centralizujemy te dane oraz analizujemy je w celu oceny
                        oraz przewidywania Twoich preferencji osobistych czy zainteresowań.{' '}
                    </p>
                    <p>
                        W oparciu o naszą analizę wysyłamy lub wyświetlamy komunikaty oraz/ lub
                        treść dostosowaną do Twoich zainteresowań/ potrzeb.{' '}
                    </p>
                    <p>
                        Masz prawo wyrażenia sprzeciwu wobec wykorzystywania Twoich danych do
                        profilowania w niektórych okolicznościach. Proszę zapoznać się z częścią
                        „Twoje Prawa oraz Wybory”, umieszczoną poniżej.{' '}
                    </p>
                </section>
                <section id="policy-8" className={section}>
                    <h3>Kto może uzyskać dostęp do Twoich danych osobowych?</h3>
                    <p>
                        Zapewniamy, by każda osoba działająca z naszego upoważnienia i mająca dostęp
                        do Twoich danych osobowych przetwarzała je wyłącznie na nasze polecenie,
                        chyba że inne wymagania wynikają z prawa Unii lub prawa państwa
                        członkowskiego.
                    </p>
                    <p>
                        <b>
                            Możemy dzielić się Twoimi danymi osobowymi w ramach Grupy L’Oréal na
                            podstawie naszego uzasadnionego interesu w celach wewnętrznego
                            zarządzania, zapewnienia zgodności z naszymi obowiązkami prawnymi,
                            zapobiegania oszustwom oraz/lub w celu zabezpieczenia naszych narzędzi,
                            poprawiania naszych produktów oraz usług lub po otrzymaniu Twojej zgody
                            na takie działanie.
                        </b>{' '}
                        <br />W zależności od celów dla których zostały zebrane, oraz jedynie jeśli
                        będzie to potrzebne do niektórych Twoich danych osobowych mogą mieć dostęp
                        podmioty z Grupy L’Oréal znajdujące się na całym świecie na podstawie
                        naszego uzasadnionego interesu dla celów wewnętrznego zarządzania, tam gdzie
                        to możliwe w spseudonimizowany sposób (nie pozwalający na bezpośrednią
                        identyfikację), oraz tam gdzie to konieczne do dostarczenia Ci usługi, o
                        którą się zwróciłeś.
                    </p>
                    <p>
                        Możemy również dzielić się Twoimi danymi osobowymi w spseudonimizowany
                        sposób (nie pozwalający na bezpośrednią identyfikację) z naukowcami L’Oréal
                        zajmującymi się badaniami oraz innowacjami, co obejmuje naukowców, którzy
                        znajdują się poza Twoim krajem, w celach badań oraz wprowadzania innowacji,
                        tak aby ulepszyć dostarczanie naszych produktów i usług.{' '}
                    </p>
                    <p>
                        Tam gdzie to dopuszczalne, możemy również podzielić się niektórymi Twoimi
                        danymi osobowymi, co obejmuje te zebrane za pomocą plików Cookies z innymi
                        naszymi firmami, opierając się na naszym uzasadnionym interesie w celach
                        wewnętrznego zarządzania, tak aby zharmonizować oraz uaktualnić informacje,
                        którymi się z nami dzielisz, w celu prowadzenia statystyk w oparciu o Twoją
                        charakterystykę oraz dostosowania naszych komunikatów.
                    </p>
                    <p>
                        Proszę odwiedzić stronę internetową Grupy L’Oréal w celu uzyskania dalszych
                        szczegółów na temat <a href="www.loreal.pl">Grupy L’Oréal i jej marek.</a>
                    </p>
                    <p>
                        <b>
                            Możemy dzielić się Twoimi danymi osobowymi ze stronami trzecimi lub
                            podmiotami z Grupy L’Oréal w celach marketingowych.{' '}
                        </b>
                    </p>
                    <p>
                        Dzielimy się Twoimi danymi osobowymi ze stronami trzecimi w celach
                        marketingowych jedynie za Twoją zgodą. W takiej sytuacji, Twoje dane są
                        przetwarzane przez taką stronę trzecią, jako przez administratora, a
                        zastosowanie mają jego własne regulaminy i polityki prywatności. Powinieneś
                        uważnie sprawdzić ich dokumentację, zanim zgodzisz się na udostępnienie
                        informacji takiej stronie trzeciej. Będzie to miało miejsce, jeżeli
                        przekażesz swoje dane osobowe korzystając z usług stron trzecich (np.
                        wykorzystując konto, profil na Facebook’u) i w ten sposób zawsze będziesz
                        poinformowany oraz będziesz w stanie dowiedzieć się, które strony trzecie
                        otrzymają Twoje dane osobowe.{' '}
                    </p>
                    <p>
                        <b>
                            Twoje dane osobowe mogą być również przetwarzane w naszym imieniu przez
                            naszych zaufanych dostawców, będących stronami trzecimi – podmioty
                            przetwarzające.{' '}
                        </b>
                    </p>
                    <p>
                        Opieramy się na zaufanych podmiotach trzecich w celu wykonania szeregu
                        operacji biznesowych w naszym imieniu. Przekazujemy im jedynie te
                        informacje, których potrzebują do wykonania usługi oraz wymagamy, aby nie
                        wykorzystywali Twoich danych osobowych do jakichkolwiek innych celów. Zawsze
                        dokładamy najlepszych starań, aby zapewnić, że wszystkie strony trzecie będą
                        przechowywać Twoje dane osobowe w bezpieczny sposób. Szczegółowo sprawdzamy
                        podmioty, którym powierzamy przetwarzanie Twoich danych. Zawieramy z nimi
                        szczegółowe umowy, a także dokonujemy okresowych kontroli zgodności operacji
                        przetwarzania z treścią takie umowy i przepisami prawa. Na przykład, możemy
                        powierzyć usługi, które będą wymagały przetwarzania Twoich danych osobowych:
                    </p>
                    <ul className={list}>
                        <li>
                            Podmiotom trzecim, które pomagają nam w dostarczaniu usług cyfrowych
                            oraz z zakresu handlu elektronicznego, takich jak monitorowanie opinii
                            na temat marki (social listenings), wyszukiwarek sklepów, programów
                            lojalnościowych, zarządzania tożsamością, ocen oraz opinii, CRM,
                            oprogramowania typu analytics, wyszukiwarek internetowych,
                            oprogramowanie obsługi treści tworzonych przez użytkowników
                        </li>
                        <li>
                            Agencjom reklamowym, marketingowym oraz agencjom mediów
                            społecznościowych w celu udzielenia nam pomocy w dostarczaniu reklam,
                            treści marketingowych oraz prowadzenia kampanii, analizy ich
                            efektywności, oraz zarządzania Twoim kontaktowaniem się z nami oraz
                            pytaniami;
                        </li>
                        <li>
                            Podmiotom trzecim, które są wymagane do dostarczenia Ci produktu, np.
                            usługi pocztowe/ kurierskie;
                        </li>
                        <li>
                            Podmiotom trzecim, które pomagają nam w dostarczaniu usług IT, takich
                            jak dostarczyciele platform, usług hostingu, utrzymania oraz wsparcia
                            naszych baz danych jak również naszego oprogramowania oraz aplikacji,
                            które mogą obejmować dotyczące Cię dane (usługi takie mogą czasami
                            obejmować dostęp do Twoich danych w celu wykonania koniecznych
                            czynności);
                        </li>
                        <li>
                            Dostarczycielom usług związanych z płatnościami oraz handlowym agencjom
                            informacyjnym, w celu oceny Twojej zdolności kredytowej oraz weryfikacji
                            Twoich danych, jeżeli jest to warunkiem zawarcia z Tobą umowy;
                        </li>
                        <li>
                            Podmiotom trzecim, które pomagają nam w dbaniu o klientów oraz dla celów
                            cosmetovigilance.
                        </li>
                    </ul>
                    <p>
                        <b>
                            Możemy również udostępnić Twoje dane osobowe stronom trzecim, jako nowym
                            administratorom:
                        </b>
                    </p>
                    <ul className={list}>
                        <li>
                            W sytuacji, w której sprzedamy którąś z naszych działalności lub
                            zasobów, możemy udostępnić Twoje dane osobowe nabywcy takiej
                            działalności lub zasobów. Jeżeli L’Oréal lub część jej zasobów zostanie
                            nabyta przez stronę trzecią, dane osobowe klientów, przez nią
                            przechowywane dotyczące tych zasobów, będą jednym z przekazanych
                            elementów. W takim przypadku, tam gdzie to odpowiednie, nabywca
                            działający jako administrator przetwarza Twoje dane i to jego polityka
                            prywatności określa przetwarzanie Twoich danych osobowych.
                        </li>
                        <li>
                            Jeżeli podlegamy obowiązkowi udostępnienia Twoich danych lub podzielenia
                            się nimi w celu spełnienia obowiązku prawnego lub w celu wyegzekwowania
                            lub zastosowania naszych warunków użytkowania/ sprzedaży lub innych
                            regulaminów, na które wyraziłeś zgodę, lub w celu ochrony praw,
                            własności lub bezpieczeństwa L’Oréal, naszych konsumentów oraz
                            pracowników.{' '}
                        </li>
                        <li>Jeżeli posiadamy Twoją zgodę na takie działanie.</li>
                        <li>Jeżeli pozwala nam na to prawo.</li>
                    </ul>
                    <p>
                        <b>Możemy udostępnić Twoje dane osobowe naszym partnerom:</b>
                    </p>
                    <ul className={list}>
                        <li>
                            Jeżeli usługa do której przystąpiłeś została współutworzona przez
                            L’Oréal oraz jej partnera (np. aplikacje rozwinięte przy współpracy
                            firm). W takim przypadku, L’Oreal oraz partner przetwarzają Twoje dane
                            osobowe każdy dla własnych celów. W takim przypadku Twoje dane są
                            przetwarzane: <br /> o Przez L’Oréal zgodnie z niniejszą Polityką
                            Prywatności; <br />o Przez partnera, który również działa jako
                            administrator,
                        </li>
                        <li>
                            Jeżeli zgodziłeś się otrzymywać informacje marketingowe oraz handlowe od
                            partnera L’Oréal, poprzez dedykowaną aplikację opt-in (np. przez
                            aplikację oznakowaną przez L’Oréal oraz udostępnioną jej partnerom). W
                            takim przypadku, Twoje dane są przetwarzane przez partnera działającego
                            jako administrator, zgodnie z jego własnym regulaminem oraz zgodnie z
                            jego polityką prywatności.{' '}
                        </li>
                        <li>
                            Możemy publikować treść z portali społecznościowych. W przypadku gdy
                            otworzysz treść z naszej strony, będąc zalogowanym do portalu
                            społecznościowego, pliki Cookies z takiego portalu społecznościowego
                            mogą być przechowywane na Twoim urządzeniu. Zachęcamy Cię do
                            przeczytania Polityki dotyczącej Cookies takich portali
                            społecznościowych, aby uzyskać więcej informacji. W takim przypadku
                            Twoje dane osobowe są przetwarzane przez partnera działającego jako
                            administrator zgodnie z własnym regulaminem oraz z własną polityką
                            prywatności.{' '}
                        </li>
                    </ul>
                    <p>
                        <b>Informacje, które Facebook gromadzi i udostępnia nam</b>
                    </p>
                    <p>
                        Wszystkie funkcje i usługi Facebook dostępne na naszej stronie
                        internetowej/aplikacji podlegają regulacji{' '}
                        <a href="https://www.facebook.com/full_data_use_policy">
                            Zasady dotyczące danych Facebook
                        </a>
                        , w której można uzyskać więcej informacji na temat praw do prywatności oraz
                        możliwych opcji ustawień.
                    </p>
                    <p>Poprzez używanie z tej strony/aplikacji, możesz:</p>
                    <ul className={list}>
                        <li>
                            zarejestrować się przez swój login na Facebooku. Jeśli to zrobisz,
                            zgadzasz się tym samym na podzielenie się z nami niektórymi informacjami
                            zawartymi na Twoim publicznie dostępnym profilu;
                        </li>
                        <li>
                            używać wtyczek społecznościowych Facebook takich jak „Lubię to” bądź
                            „Udostępnij” nasze treści na platformie Facebook;
                        </li>
                        <li>
                            zaakceptować pliki Cookies ze strony internetowej/aplikacji (zwane
                            również jako Facebook Pixel), które pomagają nam zrozumieć Twoją
                            aktywność, w tym informacje o Twoim urządzeniu w jaki sposób korzystasz
                            z naszych usług, dokonanych zakupach i wyświetlanych reklamach,
                            niezależnie od tego, czy masz konto na Facebooku lub czy jesteś
                            zalogowany na Facebooku.
                        </li>
                    </ul>
                    <p>
                        Gdy korzystasz z powyżej wymienionych funkcji Facebooka, zbieramy dane,
                        które pomagają nam:
                    </p>
                    <ul className={list}>
                        <li>
                            wyświetlanie Ci reklam którymi możesz być zainteresowany na Facebooku
                            (lub Instagramie, Messengerze lub w innej usłudze Facebooka);
                        </li>
                        <li>
                            mierzyć i analizować skuteczność naszej strony internetowej/aplikacji
                            oraz reklam
                        </li>
                    </ul>
                    <p>
                        Możemy również wykorzystywać dane osobowe udostępnione nam w ramach strony
                        internetowej/aplikacji (takie jak imię i nazwisko, adres mailowy, adres
                        zamieszkania, płeć i numer telefonu) w celu identyfikacji użytkownika na
                        Facebooku (lub Instagramie, Messengerze lub w innych usługach Facebooka) w
                        celu pokazania Ci reklam, które będą lepiej dopasowane do Ciebie. Robiąc to,
                        Facebook nie udostępnia Twoich danych osobowych i usuwa je niezwłocznie po
                        zakończeniu dopasowania reklamy.
                    </p>
                    <p>
                        <b>Informacje o Google</b>
                    </p>
                    <p>
                        Gdy korzystamy z usług reklamowych Google na naszych stronach
                        internetowych/aplikacjach, Google będzie uzyskiwał dostęp do Twoich danych
                        osobowych i będzie z nich mógł korzystać.
                    </p>
                    <p>
                        Jeśli chcesz dowiedzieć się więcej na temat tego, w jaki sposób Google
                        wykorzystuje Twoje dane osobowe w tym kontekście, zapoznaj się z{' '}
                        <a href="https://policies.google.com/">Google Prywatność i Warunki</a>,
                        które regulują korzystanie z usług Google oraz sposoby przetwarzania danych
                        przez Google.
                    </p>
                    <p>
                        <b>Nie oferujemy ani nie sprzedajemy Twoich danych osobowych</b>
                    </p>
                </section>
                <section id="policy-9" className={section}>
                    <h3>Gdzie przechowujemy Twoje dane osobowe</h3>
                    <p>
                        Dane, które od Ciebie zbieramy mogą być przekazane, przechowywane oraz może
                        być do nich uzyskany dostęp spoza Europejskiego Obszaru Gospodarczego (EOG).
                        Mogą być również przetwarzane przez pracowników, działających poza EOG,
                        którzy pracują dla nas lub dla jednego z naszych dostawców usług. <br />
                    </p>
                    <p>
                        L’Oréal przekazuje dane osobowe poza EOG jedynie w bezpieczny oraz zgodny z
                        prawem sposób. Z uwagi na fakt, że niektóre kraje mogą nie posiadać praw
                        regulujących wykorzystanie oraz przekazanie danych osobowych, podejmujemy
                        kroki w celu zapewnienia, że takie strony trzecie przystępują do zobowiązań
                        określonych w niniejszej polityce. Kroki te mogą obejmować przegląd
                        standardów prywatności oraz bezpieczeństwa stron trzecich oraz zawarcie
                        odpowiednich umów (opartych na wzorze przyjętym przez Komisję Europejską,
                        dostępnym tutaj).
                    </p>
                    <p>
                        Poinformujemy Cię odrębnie oraz dodatkowo o okolicznościach przekazania
                        danych poza EOG, jeżeli przekażesz nam dane osobowe, które będą przekazane
                        poza EOG.{' '}
                    </p>
                    <p>
                        W celu uzyskania dalszych informacji skontaktuj się z nami, zgodnie z
                        informacją w części „Kontakt” poniżej.
                    </p>
                </section>
                <section id="policy-10" className={section}>
                    <h3>Jak długo przechowujemy Twoje dane osobowe</h3>
                    <p>
                        Przechowujemy Twoje dane osobowe jedynie przez taki okres jaki jest
                        potrzebny dla celu, dla którego wykorzystujemy Twoje dane osobowe, aby
                        spełnić Twoje potrzeby lub aby zapewnić zgodność z obowiązkami prawnymi,
                        którym podlegamy. <br />
                        Korzystamy z następujących kryteriów ustalenia okresu przechowywania Twoich
                        danych: <br />
                        <ul className={list}>
                            <li>
                                W przypadku gdy kupujesz nasze produkty lub usługi, przechowujemy
                                Twoje dane osobowe przez okres trwania naszej umowy;
                            </li>
                            <li>
                                W przypadku gdy uczestniczysz w ofercie promocyjnej, przechowujemy
                                Twoje dane osobowe przez okres trwania tej oferty;
                            </li>
                            <li>
                                W przypadku gdy zwracasz się do nas z pytaniem, przechowujemy Twoje
                                dane osobowe przez okres konieczny do rozpatrzenia Twojego pytania;
                            </li>
                            <li>
                                W przypadku gdy tworzysz konto, przechowujemy Twoje dane osobowe do
                                momentu w którym zobowiążesz nas do ich usunięcia lub po okresie
                                braku aktywności (brak aktywnej interakcji z naszymi firmami), który
                                jest określony zgodnie z regulacjami oraz wytycznymi w danym kraju;
                            </li>
                            <li>
                                W przypadku umieszczenia plików Cookies na Twoim komputerze,
                                przechowujemy je tak długo jak jest to konieczne do osiągnięcia ich
                                celów (tj. przez okres trwania sesyjnych plików Cookies związanych z
                                dokonywaniem zakupu lub identyfikacyjnych plików sesyjnych), oraz
                                przez okres określony z godnie z prawem oraz wytycznymi w danym
                                kraju.{' '}
                            </li>
                        </ul>
                        <p>
                            Te uprzednio wspomniane terminy mogą być przedłużone w oparciu o nasze
                            zobowiązania prawne lub regulacyjne, jak również na podstawie naszego
                            uzasadnionego interesu, w celu zarządzania naszymi prawami (np. w celu
                            zabezpieczenia naszych roszczeń w Sądzie) lub do celów statystycznych
                            lub historycznych.{' '}
                        </p>
                        <p>
                            Gdy nie potrzebujemy już korzystać lub przechowywać Twoich danych
                            osobowych, są one usuwane z naszych systemów oraz rejestrów lub
                            anonimizowane, tak aby nie można już było Cię zidentyfikować na ich
                            podstawie. Usuwanie danych osobowych jest całkowite i trwałe.
                        </p>
                    </p>
                </section>
                <section id="policy-11" className={section}>
                    <h3>Czy Twoje dane osobowe są bezpieczne?</h3>
                    <p>
                        Zobowiązujemy się to bezpiecznego przechowywania Twoich danych oraz podjęcia
                        wszelkich uzasadnionych środków ostrożności w tym celu. Zobowiązujemy
                        umownie zaufane strony trzecie, które zajmują się dla nas Twoimi danymi
                        osobowymi do tego samego. <br />
                    </p>
                    <p>
                        Zawsze czynimy co w naszej mocy, aby chronić Twoje dane osobowe a po ich
                        otrzymaniu, korzystamy ze ścisłych procedur oraz środków bezpieczeństwa, aby
                        próbować zapobiegać nieuprawnionemu do nich dostępowi. Ponieważ transmisja
                        danych przez Internet nie jest całkowicie bezpieczna, nie możemy
                        zagwarantować bezpieczeństwa Twoich danych przekazywanych na naszą stronę
                        internetową. W związku z tym, każde przekazanie danych odbywa się na Twoje
                        własne ryzyko.
                        <br />
                    </p>
                    <p>
                        Wdrażamy odpowiednie środki techniczne i organizacyjne, aby zapewnić stopień
                        bezpieczeństwa odpowiadający ewentualnemu ryzyku naruszenia praw lub
                        wolności osób fizycznych o różnym prawdopodobieństwie wystąpienia i wadze
                        zagrożenia. Opracowujemy także polityki i procedury, jak również
                        organizujemy regularne szkolenia podnoszące wiedzę i kompetencje naszych
                        prawników w tym obszarze.
                    </p>
                    <p>
                        Aby sprostać wymogom prawa opracowaliśmy szczegółowe procedury obejmujące
                        takie zagadnienia, jak m.in.:
                    </p>
                    <ul className={list}>
                        <li>ochrona danych w fazie projektowani i domyślna ochrona danych,</li>
                        <li>ocena skutków dla ochrony danych, </li>
                        <li>notyfikacja naruszeń</li>
                        <li>sporządzanie rejestru czynności przetwarzania danych,</li>
                        <li>retencja danych,</li>
                        <li>realizacja praw osób, których dane dotyczą</li>
                    </ul>
                    <p>
                        Regularnie sprawdzamy i aktualizujemy naszą dokumentację, by móc wykazać
                        spełnienie wymagań prawa zgodnie ze sformułowaną w RODO zasadą
                        rozliczalności, ale też w trosce o interesy osób, których dane dotyczą
                        staramy się inkorporować do niej najlepsze praktyki rynkowe.{' '}
                    </p>
                </section>
                <section id="policy-12" className={section}>
                    <h3>
                        Linki do stron internetowych należących do stron trzecich oraz login
                        społecznościowy
                    </h3>
                    <p>
                        Nasze strony internetowe oraz aplikacje mogą czasami zawierać linki do oraz
                        od stron internetowych sieci naszych partnerów, reklamodawców oraz spółek
                        powiązanych. Jeżeli użyjesz linku do którejś z tych strony, proszę pamiętać,
                        ze strony te posiadają własne polityki prywatności oraz że nie jesteśmy
                        odpowiedzialni oraz nie możemy być pociągnięci do odpowiedzialności za te
                        polityki. Prosimy o zapoznanie się z tymi politykami zanim podasz
                        jakiekolwiek swoje dane osobowe na tych stronach.{' '}
                    </p>
                    <p>
                        Możemy również zaoferować Ci możliwość korzystania z Twojego loginu mediów
                        społecznościowych. Jeżeli się na to zdecydujesz, powinieneś być świadomym,
                        że dzielisz się z nami swoimi informacjami profilowymi, w zależności od
                        ustawień portalu społecznościowego. Prosimy o odwiedzenie odpowiedniego
                        portalu społecznościowego oraz zapoznanie się z jego polityką prywatności w
                        celu zrozumienia w jaki sposób Twoje dane osobowe są udostępnianie oraz
                        wykorzystywane w tym kontekście.{' '}
                    </p>
                </section>
                <section id="policy-13" className={section}>
                    <h3>Media społecznościowe oraz treść generowana przez użytkowników</h3>
                    <p>
                        Niektóre z naszych stron oraz aplikacji pozwalają użytkownikom na
                        dostarczenie ich własnej treści. Proszę pamiętaj, że wszelka treść, którą
                        przekazujesz jednej z naszych platform społecznościowych może być dostępna
                        publicznie, tak więc powinieneś być ostrożny podając niektóre informacje,
                        np. informacje finansowe lub dane adresowe. Nie jesteśmy odpowiedzialni za
                        jakiekolwiek działania podejmowane przez inne osoby, jeżeli umieścisz swoje
                        dane osobowe na jednej z naszych platform mediów społecznościowych oraz
                        zalecamy, abyś nie dzielił się takimi informacjami.
                    </p>
                    <p>
                        Ogólnie rzecz ujmując, tylko Twoje dane osobowe mogą być nam udostępnione.
                        Jeżeli przekazujesz nam dane osobowe innych osób, których dane dotyczą
                        jesteś ich administratorem i powinieneś zapewnić, aby takie udostępnienie
                        było zgodne z prawem.
                    </p>
                </section>
                <section id="policy-14" className={`${section} ${overflow}`}>
                    <h3>TWOJE PRAWA ORAZ WYBORY</h3>
                    <p>
                        L’Oréal szanuje Twoje prawo do prywatności. Podejmujemy odpowiednie środki,
                        aby w zwięzłej, przejrzystej, zrozumiałej i łatwo dostępnej formie, jasnym i
                        prostym językiem, udzielić wszelkich stosownych informacji oraz prowadzić z
                        Tobą wszelką komunikację w sprawie przetwarzania danych osobowych w związku
                        z realizacją przysługujących Ci praw:
                    </p>
                    <div className={`${table} ${tableEven}`}>
                        <div>
                            <p>
                                <b>Twoje prawa</b>
                            </p>
                            <p>
                                <b>Co to oznacza?</b>
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo do bycia <b> poinformowanym</b>
                            </p>
                            <p>
                                Masz prawo uzyskać jasną, przejrzystą oraz łatwo zrozumiałą
                                informację odnośnie tego w jaki sposób wykorzystujemy Twoje dane
                                oraz na temat swoich praw. Z tego względu udostępniamy Ci niniejszą
                                Politykę.{' '}
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo <b> dostępu</b>
                            </p>
                            <p>
                                Masz prawo dostępu do swoich danych osobowych, które przechowujemy
                                (co podlega pewnym ograniczeniom). Możemy żądać rozsądnej opłaty
                                biorąc pod uwagę koszty administracyjne udzielenia takich
                                informacji. Wnioski ewidentnie nieuzasadnione, nadmierne lub
                                powtarzające się mogą pozostać bez odpowiedzi. <br /> Aby skorzystać
                                z prawa prosimy o kontakt, zgodnie z informacjami podanymi poniżej.{' '}
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo do <b>sprostowania</b>
                            </p>
                            <p>
                                Masz prawo do sprostowania swoich danych jeżeli są niepoprawne,
                                nieaktualne oraz/lub ich uzupełnienia jeżeli są niekompletne. <br />{' '}
                                Aby to zrobić prosimy o kontakt, zgodnie z informacjami podanymi
                                poniżej. Jeżeli posiadasz konto, być może łatwiej będzie Ci poprawić
                                dane poprzez funkcje „Moje konto”.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo <b> usunięcia/ prawo do bycia zapomnianym</b>
                            </p>
                            <p>
                                W niektórych sytuacjach masz prawo do usunięcia swoich danych.
                                Proszę zauważyć, że nie jest to prawo absolutne, ponieważ możemy
                                posiadać podstawy prawne lub uzasadniony interes do dalszego
                                posiadania Twoich danych. <br />
                                Jeżeli chcesz usunąć swoje dane prosimy o kontakt, zgodnie z
                                informacjami podanymi poniżej.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo do{' '}
                                <b>
                                    wyrażenia sprzeciwu wobec zautomatyzowanego przetwarzania, w tym
                                    profilowania
                                </b>
                            </p>
                            <p>
                                Możesz odstąpić lub skorzystać z opcji opt-out w odniesieniu do
                                komunikacji marketingowej w dowolnym momencie. <br />
                                Najłatwiej jest to uczynić poprzez kliknięcie linku „wypisz się” w
                                jakimkolwiek emailu lub komunikacie, który do Ciebie wysyłamy. W
                                innym przypadku, możesz się z nami skontaktować korzystając z danych
                                kontaktowych wskazanych poniżej. <br /> <br />
                                Jeżeli chcesz wyrazić sprzeciw wobec profilowania, prosimy o
                                kontakt, zgodnie z informacjami podanymi poniżej.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo{' '}
                                <b>
                                    wycofania zgody w każdym momencie w odniesieniu do przetwarzania
                                    danych opartego na zgodzie.{' '}
                                </b>
                            </p>
                            <p>
                                Możesz wycofać swoją zgodę na przetwarzanie przez nas Twoich danych,
                                jeżeli przetwarzanie takie opiera się na zgodzie. <br />
                                Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania
                                opartego na zgodzie przed jej wycofaniem. Chcielibyśmy odesłać Cię
                                do części „Jakie dane od Ciebie zbieramy oraz w jaki sposób je
                                wykorzystujemy”, abyś mógł określić, które z naszych operacji
                                przetwarzania opierają się na zgodzie. Jeżeli chciałbyś wyrazić
                                sprzeciw lub wycofać zgodę na przetwarzanie prosimy o kontakt,
                                zgodnie z informacjami podanymi poniżej.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo{' '}
                                <b>
                                    wyrażenia sprzeciwu wobec przetwarzania danych opartego na
                                    uzasadnionym interesie
                                </b>
                            </p>
                            <p>
                                Możesz w każdej chwili wyrazić sprzeciw wobec przetwarzania przez
                                nas Twoich danych, jeżeli przetwarzanie takie oparte jest na
                                uzasadnionym interesie. Chcielibyśmy odesłać Cię do części tabeli
                                „Jakie dane od Ciebie zbieramy oraz w jaki sposób je
                                wykorzystujemy”, w szczególności do kolumny „Jakie są nasze podstawy
                                prawne przetwarzania Twoich danych?” abyś mógł określić, które z
                                naszych operacji przetwarzania opierają się na uzasadnionym
                                interesie. Proszę zauważyć, że nie jest to prawo absolutne, ponieważ
                                możemy posiadać podstawy prawne lub uzasadniony interes do dalszego
                                posiadania oraz innego przetwarzania Twoich danych osobowych <br />{' '}
                                <br />
                                Aby skorzystać z prawa prosimy o kontakt, zgodnie z informacjami
                                podanymi poniżej. <br />
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo <b>złożenia skargi do organu nadzorczego </b>
                            </p>
                            <p>
                                Masz prawa od skontaktowania się z organem ochrony danych w Twoim
                                kraju w celu złożenia skargi na działania L’Oréal obszarze ochron
                                danych oraz prywatności <br />
                                Nie wahaj się skontaktować z nami, zgodnie z danymi podanymi niżej,
                                przed złożeniem skargi do odpowiedniego organu ochrony danych.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo do <b>przenoszenia danych</b>
                            </p>
                            <p>
                                Masz prawo przenieść, skopiować lub przekazać dane z naszej bazy
                                danych do innej. Prawo do znajduje zastosowanie jedynie do danych,
                                które Ty przekazałeś, jeżeli przetwarzania jest dokonywane w celu
                                wykonania umowy lub na podstawie Twojej zgody oraz jest dokonywane
                                przy użyciu środków automatycznych. Chcielibyśmy odesłać Cię do
                                części tabeli „Jakie dane od Ciebie zbieramy oraz w jaki sposób je
                                wykorzystujemy”, a w szczególności do kolumny „Jaka jest nasza
                                podstawa prawna przetwarzania Twoich danych”, abyś mógł stwierdzić w
                                jakich sytuacjach nasze operacji przetwarzania służą wykonaniu umowy
                                lub opierają się na zgodzie. <br />W celu uzyskania dalszych
                                informacji prosimy o kontakt, zgodnie z informacjami podanymi
                                poniżej.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo do <b>ograniczenia przetwarzania </b>
                            </p>
                            <p>
                                Masz prawo zwrócić się z wnioskiem o ograniczenie przez nas
                                przetwarzania Twoich danych. Prawo to oznacza, że przetwarzanie
                                przez nas Twoich danych zostanie ograniczone do przechowywania i nie
                                będą one wykorzystywane ani przetwarzane w inny sposób. Ma one
                                zastosowanie w ograniczonych przypadkach określonych w Ogólnym
                                Rozporządzeniu o Ochronie Danych, które wskazano poniżej:
                                <ul className={list}>
                                    <li>
                                        osoba, której dane dotyczą (czyli Ty), kwestionuje
                                        prawidłowość danych osobowych, na okres pozwalający
                                        administratorowi sprawdzić prawidłowość tych danych
                                    </li>
                                    <li>
                                        przetwarzanie jest niezgodne z prawem, a osoba, której dane
                                        dotyczą (czyli Ty), sprzeciwia się usunięciu danych
                                        osobowych, żądając w zamian ograniczenia ich
                                        wykorzystywania;
                                    </li>
                                    <li>
                                        administrator (czyli L’Oréal) nie potrzebuje już danych
                                        osobowych do celów przetwarzania, ale są one potrzebne
                                        osobie, której dane dotyczą, do ustalenia, dochodzenia lub
                                        obrony roszczeń;
                                    </li>
                                    <li>
                                        osoba, której dane dotyczą (czyli Ty), wniosła sprzeciw
                                        wobec przetwarzania opartego na uzasadnionym interesie
                                        administratora – do czasu stwierdzenia, czy prawnie
                                        uzasadnione podstawy po stronie administratora są nadrzędne
                                        wobec podstaw sprzeciwu osoby, której dane dotyczą
                                    </li>
                                </ul>{' '}
                                <br />
                                Jeżeli chciałbyś się zwrócić o ograniczenie przetwarzania, prosimy
                                kontakt zgodnie z informacjami podanymi poniżej.
                            </p>
                        </div>
                        <div>
                            <p>
                                Prawo do <b>dezaktywowania plików Cookies</b>
                            </p>
                            <p>
                                Masz prawo dezaktywowania plików Cookies. Ustawienia domyślne
                                wyszukiwarek internetowych zazwyczaj akceptują pliki Cookies,
                                jednakże możesz je łatwo zmienić w swojej wyszukiwarce. <br />
                                Wiele plików Cookies jest wykorzystywanych w celu zwiększenia
                                użyteczności oraz polepszenia funkcjonalności stron internetowych/
                                aplikacji. Z tego względu wyłączenie plików Cookies może
                                uniemożliwić Ci korzystanie z niektórych części naszych stron
                                internetowych/ aplikacji, tak jak to wskazano w części tabeli
                                dotyczącej plików Cookies <br />
                                Jeżeli chcesz ograniczyć lub zablokować wszystkie pliki Cookies,
                                które są umieszczane przez nasze strony internetowe/ aplikacje (co
                                może uniemożliwić Ci korzystanie z niektórych części stron) lub
                                jakiekolwiek inne strony/ aplikacje, możesz to uczynić poprzez
                                ustawienia wyszukiwarki. Opcja Pomocy wbudowana w Twoją wyszukiwarkę
                                powinna Ci w tym pomóc. W celu uzyskania dalszych informacji prosimy
                                o zapoznanie się z poniższym linkiem:{' '}
                                <a href="http://www.aboutcookies.org/">
                                    http://www.aboutcookies.org/
                                </a>
                                ;
                            </p>
                        </div>
                    </div>{' '}
                    <br />
                    <p>
                        Aby rozpatrzyć Twój wniosek, może być potrzebne potwierdzenie Twojej
                        tożsamości.{' '}
                    </p>
                    <p>
                        Informacji udzielamy na piśmie lub w inny sposób, w tym – w stosownych
                        przypadkach – elektronicznie. Jeżeli tego zażądasz, informacji możemy
                        udzielić ustnie, o ile innymi sposobami potwierdzimy Twoją tożsamość. Jeśli
                        przekażesz swoje żądanie elektronicznie, w miarę możliwości informacje także
                        zostaną przekazane elektronicznie, chyba że wskażesz nam inną preferowaną
                        formę komunikacji.
                    </p>
                    <p>
                        W celu kontaktu odnośnie realizacji danego prawa wyślij wiadomość na adres
                        personal-da@loreal.com lub zadzwoń do Biura Obsługi Klienta: 22 33 58 600.
                    </p>
                    <p>
                        Informacji staramy się udzielać bez zbędnej zwłoki – z zasady w terminie
                        miesiąca od otrzymania żądania. W razie potrzeby termin ten przedłużymy o
                        kolejne dwa miesiące z uwagi na skomplikowany charakter żądania lub liczbę
                        żądań. Jednak w każdym przypadku, w terminie miesiąca od otrzymania żądania,
                        poinformujemy Cię o podjętych działaniach i (w stosownych przypadkach) o
                        przedłużeniu terminu, z podaniem przyczyny takiego opóźnienia.
                    </p>
                </section>
                <section id="policy-15" className={section}>
                    <h3>Zmiany w naszej Polityce Prywatności</h3>
                    <p>
                        Okresowo możemy zmieniać niniejszą politykę prywatności. Umieścimy zmiany w
                        tej polityce oraz sugerujemy, abyś od czasu do czasu odwiedzał niniejszą
                        politykę, w celu uzyskania informacji na temat jakichkolwiek zmian.
                    </p>
                </section>
                <section id="policy-16" className={section}>
                    <h3>KONTAKT</h3>
                    <p>
                        Jeżeli posiadasz jakiekolwiek pytania lub wątpliwości odnośnie tego w jaki
                        sposób traktujemy oraz wykorzystujemy Twoje dane osobowe, lub jeżeli
                        chciałbyś skorzystać z którychkolwiek ze swoich praw wymienionych powyżej,
                        prosimy o kontakt na adres email:{' '}
                        <a href="mailto:personal-da@loreal.com">personal-da@loreal.com</a>.
                    </p>
                    <p>
                        Firma L’Oréal Polska Sp. Z o.o. jest odpowiedzialna za przetwarzanie danych
                        osobowych, którymi się z nami dzielisz. Kiedy mówimy „L’Oréal”, „nam”,
                        „nasze”, „my”, właśnie do tego podmiotu się odnosimy. L’Oréal jest
                        „administratorem” w rozumieniu właściwego prawa ochrony danych.
                    </p>
                    <p>
                        L'Oréal Polska Sp. z o.o. <br />
                        Ul. Grzybowska 62 <br />
                        00-844 Warszawa <br />
                        Polska
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyTemplate;
